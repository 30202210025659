.light-font-color {
  color: #F6F2FF;
}
.font-size-24 {
  font-size: 24px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-64 {
  font-size: 64px;
}
.radius-12 {
  border-radius: 12px;
}
.radius-16 {
  border-radius: 16px;
}
.radius-8 {
  border-radius: 16px;
}
.mainContainer {
  max-width: 1200px;
  margin-left: auto;
  display: flex;
  margin-right: auto;
}
.marginTop-16 {
  margin-top: 16px;
}
.gradientText {
  background: linear-gradient(to right, #F6F2FF, #D4BBFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.line {
  width: 120px;
  height: 1px;
  background-image: linear-gradient(to right, #F6F2FF, #D4BBFF);
  margin-top: 16px;
}
.gradientButton {
  background-image: linear-gradient(to right, #F6F2FF, #D4BBFF);
  padding: 18px 36px;
  border: none;
  cursor: pointer;
}
.outline {
  background: transparent;
  border-color: #F6F2FF;
  padding: 18px 36px;
  color: #F6F2FF;
  border: 1px solid #F6F2FF;
  cursor: pointer;
}
