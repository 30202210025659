.light-font-color {
  color: #F6F2FF;
}
.font-size-24 {
  font-size: 24px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-64 {
  font-size: 64px;
}
.radius-12 {
  border-radius: 12px;
}
.radius-16 {
  border-radius: 16px;
}
.radius-8 {
  border-radius: 16px;
}
.mainContainer {
  max-width: 1200px;
  margin-left: auto;
  display: flex;
  margin-right: auto;
}
.marginTop-16 {
  margin-top: 16px;
}
.gradientText {
  background: linear-gradient(to right, #F6F2FF, #D4BBFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.line {
  width: 120px;
  height: 1px;
  background-image: linear-gradient(to right, #F6F2FF, #D4BBFF);
  margin-top: 16px;
}
.landingPage {
  position: relative;
}
.landingPage .box {
  position: absolute;
  top: 0;
  right: 0;
  background: url('../../background.png'), url('../../light-background.png');
  background-size: cover;
  width: 30%;
  z-index: -1;
  height: 950px;
}
.landingPage .header {
  padding-top: 35px;
}
.landingPage .introduceTitleContainer {
  display: flex;
  margin-top: 84px;
}
.landingPage .introduceTitleContainer .arrow {
  margin-top: 48px;
}
.landingPage .vectorContainer {
  border-top: 1px solid #F6F2FF;
  margin-top: auto;
  display: flex;
  max-width: 440px;
  margin-top: 151px;
  padding: 24px 0;
  justify-content: space-between;
}
.landingPage .titleContainer {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
}
.landingPage .titleContainer .actionContainer {
  display: flex;
  margin-top: 40px;
}
.landingPage .titleContainer .actionContainer .actionButton {
  width: 90%;
}
.landingPage .titleContainer .actionContainer .marginLeft {
  margin-left: 8px;
}
.landingPage .titleContainer .actionContainer .marginRight {
  margin-right: 8px;
}
.landingPage .title {
  z-index: 1;
  color: #F6F2FF;
  font-weight: bold;
  width: fit-content;
}
.landingPage .description {
  color: #F6F2FF;
  max-width: 440px;
  width: 100%;
  margin-top: 48px;
  line-height: 21px;
  opacity: 0.7;
}
.landingPage .introSection {
  margin-top: 84px;
  justify-content: space-between;
}
.landingPage .introSection .rightContainer {
  display: flex;
  align-items: center;
}
.landingPage .introSection .rightContainer .vector {
  margin-right: 35px;
}
.landingPage .introSection .rightContainer .socialItem {
  margin: 13px 0;
}
.landingPage .introSection .socialContainer {
  display: flex;
  flex-direction: column;
}
.landingPage .aboutSection {
  position: relative;
  margin-top: 48px;
  display: flex;
}
.landingPage .aboutSection .aboutContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 188px 0;
}
.landingPage .aboutSection .aboutContainer .aboutItemsContainer {
  flex: 1;
  z-index: 1;
  margin-right: 36px;
  width: 100px;
  margin-left: 177px;
}
.landingPage .aboutSection .aboutContainer .marginTop {
  margin-top: 80px;
}
.landingPage .aboutSection .aboutContainer .actionButton {
  z-index: 1;
  min-width: 180px;
}
.landingPage .aboutSection .img {
  background: url('../../background.png'), url('../../light-background.png');
  background-size: cover;
  position: absolute;
  left: 0;
  width: 606px;
  height: 100%;
}
.landingPage .featureDetailContainer {
  margin-top: 44px;
}
.landingPage .featureContainer {
  margin-top: 120px;
}
.landingPage .featureContainer .featureTitleContainer {
  flex: 1;
  width: 100%;
}
.landingPage .featureContainer .featureListContainer {
  flex: 1;
}
.landingPage .featureContainer .featureListContainer__icon {
  width: 56px;
  height: 56px;
}
.landingPage .featureContainer .featureListContainer__marginTop {
  margin-top: 48px;
}
.landingPage .featureContainer .featureVector {
  flex: 1;
  margin-right: 56px;
}
.landingPage .featureContainer .featureDescription {
  flex: 1;
  color: #FFFFFF;
  width: 100%;
  min-width: 432px;
  border-left: 1px solid #FFFFFF;
  padding-left: 10px;
}
.landingPage .featureContainer .featureDescription__description {
  line-height: 21px;
}
.landingPage .tokenomicsContainer {
  margin-top: 205px;
}
.landingPage .tokenomicsContainer .featureTitleContainer {
  flex: 1;
  width: 100%;
}
.landingPage .tokenomicsContainer .featureDescription {
  flex: 1;
  color: #FFFFFF;
  width: 100%;
  min-width: 432px;
  border-left: 1px solid #FFFFFF;
  padding-left: 10px;
}
.landingPage .tokenomicsContainer .featureDescription__description {
  line-height: 21px;
}
.landingPage .tokenomicsDetailContainer {
  margin-top: 92px;
}
.landingPage .tokenomicsDetailContainer .tokenomicsItemsContainer {
  flex: 1;
}
.landingPage .tokenomicsDetailContainer .tokenomicsItemsContainer__tokenomicsItemsRow {
  display: flex;
  flex-direction: row;
  margin-right: 161px;
  flex-direction: column;
}
.landingPage .tokenomicsDetailContainer .tokenomicsItemsContainer__row {
  margin-top: 80px;
  display: flex;
}
.landingPage .tokenomicsDetailContainer .tokenomicsItemsContainer__row__item {
  flex: 1;
}
.landingPage .tokenomicsDetailContainer__circleVector {
  flex: 1;
}
.landingPage .roadMapContainer {
  margin-top: 247px;
}
.landingPage .roadMapContainer .featureTitleContainer {
  flex: 1;
  width: 100%;
}
.landingPage .roadMapContainer .featureDescription {
  flex: 1;
  color: #FFFFFF;
  width: 100%;
  min-width: 432px;
  border-left: 1px solid #FFFFFF;
  padding-left: 10px;
}
.landingPage .roadMapContainer .featureDescription__description {
  line-height: 21px;
}
.landingPage .appDownloadContainer {
  position: relative;
  margin-top: 86px;
}
.landingPage .appDownloadContainer .backgroundContainer {
  display: flex;
  height: 900px;
}
.landingPage .appDownloadContainer .backgroundContainer .purple {
  flex: 1;
  background: url('../../background.png'), url('../../light-background.png');
  background-size: cover;
}
.landingPage .appDownloadContainer .backgroundContainer .transparent {
  flex: 1;
}
.landingPage .appDownloadContainer .appDownloadContainerMain {
  display: flex;
  width: 100%;
  justify-content: space-between;
  top: 0;
  position: absolute;
}
.landingPage .appDownloadContainer .appDownloadContainerMain .phoneVector {
  margin-top: 90px;
  margin-left: 36p;
}
.landingPage .appDownloadContainer .appDownloadContainerMain .appDownloadDescriptionContianer {
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.landingPage .appDownloadContainer .appDownloadContainerMain .appDownloadDescriptionContianer__header {
  font-size: 49px;
  font-weight: bold;
  color: #F6F2FF;
}
.landingPage .appDownloadContainer .appDownloadContainerMain .appDownloadDescriptionContianer ul {
  margin-top: 32px;
  line-height: 24px;
  padding-left: 0;
}
.landingPage .appDownloadContainer .appDownloadContainerMain .appDownloadDescriptionContianer li {
  color: #F6F2FF;
  list-style: none;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.landingPage .appDownloadContainer .appDownloadContainerMain .appDownloadDescriptionContianer li div {
  height: 3px;
  width: 3px;
  margin-right: 14px;
  background: #F6F2FF;
  border-radius: 50%;
}
.landingPage .appDownloadContainer .appDownloadContainerMain .appDownloadDescriptionContianer__actionTitle {
  margin-top: 40px;
}
.landingPage .appDownloadContainer .appDownloadContainerMain .appDownloadDescriptionContianer__actionButtons {
  display: flex;
  background: transparent;
  color: #F6F2FF;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}
.landingPage .appDownloadContainer .appDownloadContainerMain .appDownloadDescriptionContianer__actionButtons .googleplayBtn {
  flex: 1;
  width: 100%;
  border: 1px solid #F6F2FF;
  padding: 18px 0;
  margin-right: 8px;
}
.landingPage .appDownloadContainer .appDownloadContainerMain .appDownloadDescriptionContianer__actionButtons .btnTextContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 24px;
}
.landingPage .appDownloadContainer .appDownloadContainerMain .appDownloadDescriptionContianer__actionButtons .btnTextContainer__title {
  font-weight: normal;
}
.landingPage .appDownloadContainer .appDownloadContainerMain .appDownloadDescriptionContianer__actionButtons .btnTextContainer__description {
  font-weight: normal;
}
.landingPage .appDownloadContainer .appDownloadContainerMain .appDownloadDescriptionContianer__actionButtons .btnDetail {
  display: flex;
  flex-direction: row;
}
.landingPage .appDownloadContainer .appDownloadContainerMain .appDownloadDescriptionContianer__actionButtons .applestoreBtn {
  flex: 1;
  margin-left: 8px;
  width: 100%;
  border: 1px solid #F6F2FF;
  padding: 18px 0;
}
.landingPage .faqContainer {
  margin-top: 120px;
}
.landingPage .faqContainer .featureTitleContainer {
  flex: 1;
  width: 100%;
}
.landingPage .faqContainer .featureDescription {
  flex: 1;
  color: #FFFFFF;
  width: 100%;
  min-width: 432px;
  border-left: 1px solid #FFFFFF;
  padding-left: 10px;
}
.landingPage .faqContainer .featureDescription__description {
  line-height: 21px;
}
.landingPage .expandContainer {
  margin-top: 76px;
}
.landingPage .carouselContainer {
  margin: 190px 165px 0;
}
.landingPage .carouselContainer .carouselContainer__pagination {
  display: flex;
  margin-top: 56px;
  align-items: center;
}
.landingPage .carouselContainer .carouselContainer__pagination--active {
  width: 27px;
  height: 8px;
  background-image: linear-gradient(to right, #F6F2FF, #D4BBFF);
  border-radius: 20px;
  margin: 0 2px;
}
.landingPage .carouselContainer .carouselContainer__pagination--deActive {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-image: linear-gradient(to right, #F6F2FF, #D4BBFF);
  opacity: 0.2;
  margin: 0 2px;
}
.landingPage .roadmapVectorContainer {
  margin-top: 75px;
}
.landingPage .footerContainer {
  background: url('../../background.png'), url('../../light-background.png');
  background-size: cover;
  width: 100%;
  margin-top: 80px;
}
.landingPage .footerContainer__left {
  margin-top: 124px;
  display: flex;
  margin-right: 66.5px;
  flex: 1;
  flex-direction: column;
}
.landingPage .footerContainer__left__joinText {
  margin-top: 40px;
}
.landingPage .footerContainer__left__applyBtn {
  width: fit-content;
  margin-top: 24px;
  padding: 18px 39px;
}
.landingPage .footerContainer__left__phoneContainer {
  display: flex;
  align-items: center;
  margin-top: 40px;
  padding: 0 24px;
}
.landingPage .footerContainer__left__phoneContainer .phone {
  margin-left: 20px;
  color: #F6F2FF;
}
.landingPage .footerContainer__left__emailContainer {
  display: flex;
  align-items: center;
  padding: 23px 24px 25px 24px;
  border: 1px solid #F6F2FF;
  border-radius: 8px;
  width: 343px;
  margin-top: 34px;
}
.landingPage .footerContainer__left__emailContainer .mail {
  color: #F6F2FF;
  margin-left: 20px;
}
.landingPage .footerContainer__left .followText {
  margin-top: 146px;
}
.landingPage .footerContainer__left .socialMediaListContainer {
  display: flex;
  margin-top: 24px;
  margin-bottom: 136px;
}
.landingPage .footerContainer__left .socialMediaListContainer .circle {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #F6F2FF;
  border-radius: 50%;
}
.landingPage .footerContainer__left .socialMediaListContainer .circle svg {
  fill: #F6F2FF;
}
.landingPage .footerContainer__left .socialMediaListContainer .circle:hover {
  background: #F6F2FF;
}
.landingPage .footerContainer__left .socialMediaListContainer .circle:hover svg {
  fill: #3445ED;
}
.landingPage .footerContainer__left .socialMediaListContainer .circle__marginLeft {
  margin-left: 13px;
}
.landingPage .footerContainer__right {
  margin-top: 124px;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 56px 64px;
  align-self: flex-start;
  margin-left: 66.5;
  background-image: linear-gradient(to right, #F6F2FF, #D4BBFF);
  border-radius: 16px;
}
.landingPage .footerContainer__right__description {
  color: #010828;
  font-size: 20px;
  line-height: 30px;
}
.landingPage .footerContainer__right .TextField {
  border: 1px solid #FFFFFF;
  border-radius: 8px;
  background: transparent;
  background-color: transparent;
  box-shadow: none;
  overflow: hidden;
  margin-top: 24px;
  color: black;
}
.landingPage .footerContainer__right .TextField:focus-within {
  border: 1px solid #1C0F3080;
}
.landingPage .footerContainer__right input {
  background: transparent !important;
  color: black !important;
}
.landingPage .footerContainer__right div {
  background: transparent;
}
.landingPage .footerContainer__right label {
  color: #A5ADCF;
}
.landingPage .footerContainer__right .customInput {
  color: black;
}
.landingPage .footerContainer__right .inTouchBtn {
  background-color: #1C0F30;
  width: 100%;
  color: #F6F2FF;
  border: none;
  font-weight: bold;
  font-size: 14px;
  border-radius: 16px;
  padding: 19px 157px;
  margin-top: 24px;
  cursor: pointer;
}
