.font-size-24 {
  font-size: 24px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-64 {
  font-size: 64px;
}
.radius-12 {
  border-radius: 12px;
}
.radius-16 {
  border-radius: 16px;
}
.radius-8 {
  border-radius: 16px;
}
.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerContainer__typography {
  color: #F6F2FF;
  text-decoration: none;
}
.headerContainer .navItemContainer__navItem {
  color: #F6F2FF;
  text-decoration: none;
  margin: 20px;
}
.headerContainer .navItemContainer__navItem--disable {
  text-decoration: none;
  font-weight: normal;
  margin: 20px;
}
.headerContainer .navItemContainer__navItem--enable {
  text-decoration: none;
  color: #F6F2FF;
  font-weight: bold;
  margin: 20px;
}
