.light-font-color {
  color: #F6F2FF;
}
.font-size-24 {
  font-size: 24px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-64 {
  font-size: 64px;
}
.radius-12 {
  border-radius: 12px;
}
.radius-16 {
  border-radius: 16px;
}
.radius-8 {
  border-radius: 16px;
}
.mainContainer {
  max-width: 1200px;
  margin-left: auto;
  display: flex;
  margin-right: auto;
}
.marginTop-16 {
  margin-top: 16px;
}
.gradientText {
  background: linear-gradient(to right, #F6F2FF, #D4BBFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.line {
  width: 120px;
  height: 1px;
  background-image: linear-gradient(to right, #F6F2FF, #D4BBFF);
  margin-top: 16px;
}
.bottomLine {
  border-bottom: 1px solid rgba(212, 187, 255, 0.2);
  box-shadow: 0.2;
}
.expandableBoxContainer {
  display: flex;
  flex-direction: column;
  margin: 0 279px;
}
.expandableBoxContainer__titleContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 25px 38px;
}
.expandableBoxContainer__titleContainer__title {
  color: #F6F2FF;
  font-size: 20px;
  font-weight: bold;
  margin-left: 40px;
}
.expandableBoxContainer__contentContainer {
  display: flex;
  transition: 0.2s;
  max-height: 0;
  visibility: hidden;
  padding: 0 111px;
  opacity: 0;
}
.expandableBoxContainer__contentContainer__content {
  color: #FFFFFF;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  opacity: 0.7;
  border-left: 1px solid #FFFFFF;
  padding-left: 14px;
}
.expandableBoxContainer__contentContainer--open {
  opacity: 1;
  max-height: 1000px;
  visibility: visible;
  padding: 34px 111px 56px;
}
