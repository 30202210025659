.light-font-color {
  color: #F6F2FF;
}
.font-size-24 {
  font-size: 24px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-64 {
  font-size: 64px;
}
.radius-12 {
  border-radius: 12px;
}
.radius-16 {
  border-radius: 16px;
}
.radius-8 {
  border-radius: 16px;
}
.mainContainer {
  max-width: 1200px;
  margin-left: auto;
  display: flex;
  margin-right: auto;
}
.marginTop-16 {
  margin-top: 16px;
}
.gradientText {
  background: linear-gradient(to right, #F6F2FF, #D4BBFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.line {
  width: 120px;
  height: 1px;
  background-image: linear-gradient(to right, #F6F2FF, #D4BBFF);
  margin-top: 16px;
}
.featureItemContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 32px;
}
.featureItemContainer svg {
  fill: #F6F2FF;
  stroke: #1C0F30;
  padding: 8px;
  background-image: linear-gradient(to right, #F6F2FF, #D4BBFF);
  border-radius: 16px;
}
.featureItemContainer:hover {
  background-image: linear-gradient(to right, #F6F2FF, #D4BBFF);
  border-radius: 16px;
}
.featureItemContainer:hover .title {
  color: black;
  font-weight: bold;
}
.featureItemContainer:hover .featureItemContainer__featureDescription {
  color: black;
  font-weight: normal;
  margin-top: 32px;
  opacity: 0.7;
}
.featureItemContainer:hover svg {
  background-image: linear-gradient(to right, #1C0F30, #1C0F30);
  fill: #1C0F30;
  stroke: #F6F2FF;
}
.featureItemContainer .contentContainer {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
}
.featureItemContainer .title {
  color: #F6F2FF;
  font-weight: bold;
}
.featureItemContainer__featureDescription {
  color: #F6F2FF;
  font-weight: normal;
  margin-top: 32px;
  opacity: 0.7;
}
.featureItemContainer__icon {
  display: flex;
  width: 56px;
  height: 56px;
}
